/**
 * Packages
 */
import { Container, Row, Col, Badge } from 'react-bootstrap';

/**
 * Styles
 */
import './index.scss';

/**
 * Notifications component
 */
function Notifications() {
  return (
    <section className='Notifications'>
      <Container>
        <Row>
          <Col lg={{ span: 6, offset: 6 }}>
            <div data-aos="fade-left" data-aos-duration="1200">
              <p className='Notifications__pretitle'></p>
              <h2 className='Notifications__title'>Set your own stock price alerts</h2>
              <p className='Notifications__subtitle'>
                Set Price alerts when to buy or sell a stock.
                <br/>
                Get notified  when stocks in your watchlist go over 5% or 10%.
                <br/>
              </p>
            </div>
            {/* Images */}
            <div className='Notifications__notifications-img'></div>
            <div className='Notifications__notification-left-img' data-aos="fade-left" data-aos-duration="1200"></div>
            <div className='Notifications__notification-right-img' data-aos="fade-right" data-aos-duration="1200"></div>
            <div className='Notifications__graphic-img'>
            <Badge className='Notifications__buy-badge'>BUY</Badge>
            </div>
            
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Notifications;