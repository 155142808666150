/**
 * Module dependencies
 */
import { InputGroup, FormControl, Button } from 'react-bootstrap';

/**
 * Styles
 */
import './index.scss';

/**
 * Subscribe component
 */
function Subscribe(props: any) {
  return (
    <div className='Subscribe'>
      <InputGroup>
        <FormControl
          className='Subscribe__input'
          placeholder="Your email"
          aria-label="Your email"
        />
        <Button className='Subscribe__button' href={`${process.env.REACT_APP_SUBSCRIBE_URL}`}>{props.name ? props.name : ("Subscribe")}</Button>
      </InputGroup>
    </div>
  );
}

/**
 * Expose component
 */
export default Subscribe;
