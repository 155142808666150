/**
 * Packages
 */
 import { Component } from 'react';
 import { Container } from 'react-bootstrap';
 
 import Navbar from '../Navbar';
 import Footer from '../Footer';
 import Subscribe from '../../components/Subscribe';
 
 /**
  * Styles
  */
import './index.scss';

class ContactUs extends Component<{}> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
            <Navbar isLaunch={true}/>
            <Container>
                <p>
                <br />
                <h1>Contact Us</h1>
                <br />
                Please email us to {`${process.env.REACT_APP_SUPPORT_EMAIL}`}
                </p>
                <br />
            </Container>
            <Footer isLaunch={true} />
            </>
        )
    }
}

export default ContactUs;
