/**
 * Packages
 */
import { Component } from 'react';
import Navbar from './sections/Navbar';
import Hero from './sections/Hero';
import Filters from './sections/Filters';
import Notifications from './sections/Notifications';
import Visualization from './sections/Visualization';
import PreFooter from './sections/PreFooter';
import Footer from './sections/Footer';
import AOS from 'aos';

/**
 * Styles
 */
import './App.css';
import 'aos/dist/aos.css';

/**
 * App component
 */
class App extends Component<{}, { pageYOffset: number }> {
  constructor(props: any) {
    super(props);
    this.state = { pageYOffset: 0 };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    AOS.init();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({ pageYOffset: window.pageYOffset });
  }

  render() {
    const params = new URLSearchParams(document.location.search);
    const isLaunch = process.env.REACT_APP_IS_LAUNCH === 'true';
    const { pageYOffset } = this.state;

    return (
      <>
        <Navbar isLaunch={isLaunch} />
        <Hero isLaunch={isLaunch} />
        <Filters pageYOffset={pageYOffset} />
        <Visualization/>
        <Notifications/>
        <PreFooter isLaunch={isLaunch} />
        <Footer isLaunch={isLaunch} />
      </>
    );
  }
}

export default App;
