/**
 * Packages
 */
import { Container, Row, Col, Button } from 'react-bootstrap';
import Subscribe from '../../components/Subscribe';

/**
 * Images
 */
import download from './download.svg';

/**
 * Styles
 */
import './index.scss';

/**
 * Pre footer component
 */
function PreFooter(props: any) {
  return (
    <section className={`PreFooter PreFooter__${props.isLaunch ? 'launch' : 'prelaunch'}`}>
      <Container>
        <Row>
          {props.isLaunch ? (
            <Col lg={{ span: 6, offset: 3 }} className="text-center">
              <div className='PreFooter__free-trial'>
                <p className='PreFooter__free-trial-pretitle'>14-day free trial</p>
                <h2 className='PreFooter__free-trial-title'>
                  <span>$9</span>
                  <span className='PreFooter__free-trial-title-cents'>99</span>
                  <span className='PreFooter__free-trial-title-period'>per month</span>
                </h2>
                <p className='PreFooter__free-trial-subtitle'>Cancel any time.</p>
                <div className='PreFooter__free-trial-wrapper-button'>
                  <Button className='PreFooter__free-trial-button' href={`${process.env.REACT_APP_APPLE_STORE_URL}`} target='_blank'>Start <span className='d-none d-md-inline-block'>14-day</span> free trial</Button>
                </div>
                <div className='PreFooter__free-trial-wrapper-download-img'>
                  <a href={`${process.env.REACT_APP_APPLE_STORE_URL}`} target='_blank'>
                  <img
                    src={download}
                    className="PreFooter__free-trial-download-img"
                    alt="Download on the App Store"
                  />
                  </a>
                </div>
                <p className='PreFooter__free-trial-end-message'>Free trial only offered to first time subscribers.</p>
              </div>
            </Col>
          ) : (
            <Col lg={{ span: 6, offset: 3 }} className="text-center">
              <h2 className='PreFooter__title'>Get early access</h2>
              <p className='PreFooter__subtitle'>Join our waitlist and get priority access.</p>
              <div className='PreFooter__subscribe'>
                <Subscribe/>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default PreFooter;
