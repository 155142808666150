/**
 * Packages
 */
import { Container, Row, Col } from 'react-bootstrap';

/**
 * Styles
 */
import './index.scss';

/**
 * Filters component
 */
function Filters(props: any) {
  const { pageYOffset } = props;

  const scaleSmall: number = pageYOffset < 1100 ? 1 : 1 + ((pageYOffset - 1100) * 0.00030);
  const scaleLarge: number = pageYOffset < 1100 ? 1 : 1 - ((pageYOffset - 1100) * 0.00030);

  const styleSmall: any = { transform: `scale(${scaleSmall})` };
  const styleLarge: any = { transform: `scale(${scaleLarge})` };

  return (
    <section className='Filters'>
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} className="text-center">
            {/* Images */}
            <div className="Filters__topology-img"></div>
            <div className="Filters__filters-airlines-img" style={styleSmall}></div>
            <div className="Filters__filters-banking-img" style={styleSmall}></div>
            <div className="Filters__filters-biotech-img" style={styleLarge}></div>
            <div className="Filters__filters-cannabis-img" style={styleLarge}></div>
            <div className="Filters__filters-crypto-img" style={styleLarge}></div>
            <div className="Filters__filters-energy-img" style={styleLarge}></div>
            <div className="Filters__filters-europe-img" style={styleLarge}></div>
            <div className="Filters__filters-finance-img" style={styleLarge}></div>
            <div className="Filters__filters-fintech-img" style={styleLarge}></div>
            <div className="Filters__filters-pharma-img" style={styleSmall}></div>
            <div className="Filters__filters-realestate-img" style={styleLarge}></div>
            <div className="Filters__filters-technology-img" style={styleLarge}></div>
            {/* Text */}
            <p className='Filters__pretitle'></p>
            <h2 className='Filters__title'>Filters that matter</h2>
            <p className='Filters__subtitle'>Trendo lets you filter thousands of stocks with filters that matter to you. You can use quantitative filters like Revenue Growth, Industry, or founder led companies. These filters will enable you to identify investments that matter to you and  improve your investments.</p>
         </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Filters;