/**
 * Packages
 */
import { Container, Row, Col } from 'react-bootstrap';

/**
 * Styles
 */
import './index.scss';

/**
 * Visualization component
 */
function Visualization() {
  return (
    <section className='Visualization'>
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} className="text-center">
            <p className='Visualization__pretitle'></p>
            <h2 className='Visualization__title'>Superpowered Watchlist</h2>
            <p className='Visualization__subtitle'>
              Monitor your investments with a superpowered Watchlist. 
              <br/>
              Trendo is designed to enlightened your portfolio with real time financial data. 
              <br/>
              Compare your stocks and their fundamentals easily. 
              <br/>
              As easy as comparing flights.
            </p>
            {/* Images */}
            <div className='Visualization__topology-img'></div>
            <div className='Visualization__watchlists-img'></div>
            <div className='Visualization__stocks-img'></div>
            <div className='Visualization__profile-img'></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Visualization;