/**
 * Packages
 */
import { Navbar, Nav, Container } from 'react-bootstrap';
import Download from '../../components/Download';

/**
 * Images
 */
import logo from './logo.svg';

/**
 * Styles
 */
import './index.scss';

/**
 * Navbar component
 */
function NavbarComponent(props: any) {
  return (
    <Navbar className='Navbar' variant='dark'>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            className="d-inline-block Navbar__logo"
            alt="Trendo logo"
          />
        </Navbar.Brand>
        {props.isLaunch && (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse className="Navbar__navbar justify-content-end">
              <Nav>
                <Nav.Link href="#">14-day free trial</Nav.Link>
              </Nav>
              <Nav>
                <Download/>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  )
};

/**
 * 
 */
export default NavbarComponent;
