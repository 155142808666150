/**
 * Module dependencies
 */
import { Button } from 'react-bootstrap';

/**
 * Images
 */
import download from './download.svg';

/**
 * Styles
 */
import './index.scss';

/**
 * Download component
 */
function Download() {
  return (
    <Button className='Download' href={`${process.env.REACT_APP_APPLE_STORE_URL}`} target='_blank'>
      <img
        src={download}
        className="d-inline-block"
        alt="Download"
      />
      Download app
    </Button>
  );
}

/**
 * Expose component
 */
export default Download;
