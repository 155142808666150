// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Download {\n  font-family: \"Manrope\", sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  color: #000000;\n  font-size: 12.7747px;\n  line-height: 17px;\n  padding: 9px 21px;\n}\n@media (min-width: 768px) {\n  .Download {\n    font-size: 15.3296px;\n    line-height: 21px;\n    padding: 10px 26px;\n  }\n}\n.Download, .Download:active {\n  background: #BDF15D;\n  border-radius: 20.4395px;\n  border: 0;\n  box-shadow: none;\n}\n.Download:hover {\n  background: #BDF15D;\n}\n.Download img {\n  margin-right: 8px;\n  height: 13px;\n  width: 15px;\n}\n@media (min-width: 768px) {\n  .Download img {\n    margin-right: 9px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Download/index.scss"],"names":[],"mappings":"AAEA;EACE,kCAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,iBAAA;EACA,iBAAA;AAAF;AAEE;EATF;IAUI,oBAAA;IACA,iBAAA;IACA,kBAAA;EACF;AACF;AACE;EACE,mBAAA;EACA,wBAAA;EACA,SAAA;EACA,gBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,YAAA;EACA,WAAA;AADJ;AAGI;EALF;IAMI,iBAAA;EAAJ;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');\n\n.Download {\n  font-family: 'Manrope', sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  color: #000000;\n  font-size: 12.7747px;\n  line-height: 17px;\n  padding: 9px 21px;\n\n  @media (min-width: 768px) {\n    font-size: 15.3296px;\n    line-height: 21px;\n    padding: 10px 26px;\n  }\n\n  &, &:active {\n    background: #BDF15D;\n    border-radius: 20.4395px;\n    border: 0;\n    box-shadow: none;\n  }\n\n  &:hover {\n    background: #BDF15D;\n  }\n\n  img {\n    margin-right: 8px;\n    height: 13px;\n    width: 15px;\n\n    @media (min-width: 768px) {\n      margin-right: 9px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
