/**
 * Packages
 */
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Subscribe from '../../components/Subscribe';

/**
 * Images
 */
import logo from './logo.svg';

/**
 * Styles
 */
import './index.scss';

/**
 * Footer component
 */
function Footer(props: any) {
  return (
    <footer className={`Footer Footer__${props.isLaunch ? 'launch' : 'prelaunch'}`}>
      <Container>
      {props.isLaunch ? (
          <Row>
            <Col sm={3}>
              <img
                src={logo}
                className="d-inline-block align-top Footer__logo-img"
                alt="Trendo logo"
              />
            </Col>
            <Col sm={4}>
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/contact-us">Contact us</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/terms">Terms and Conditions</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/privacy">Privacy Policy</Link>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={5}>
              <div className='Footer__subscribe-description'>Subscribe to receive updates, access to exclusive deals, and more.</div>
              <Subscribe/>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <img
                src={logo}
                className="d-inline-block align-top Footer__logo-img"
                alt="Trendo logo"
              />
            </Col>
            <Col sm={6}>
              <Nav className="flex-column flex-md-row justify-content-end">
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/contact-us">Contact us</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/terms">Terms and Conditions</Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <Link to="/privacy">Privacy Policy</Link>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        )}
      </Container>
    </footer>
  );
}

export default Footer;