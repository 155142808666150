/**
 * Module dependencies
 */
import { Container, Row, Col } from 'react-bootstrap';
import Subscribe from '../../components/Subscribe';
import Download from '../../components/Download';

/**
 * Styles
 */
import './index.scss';

/**
 * Hero component
 */
function Hero(props: any) {
  return (
    <section className={`Hero Hero__${props.isLaunch ? 'launch' : 'prelaunch'}`}>
      <Container>
        <Row>
          <Col md={7}>
            <h1 className='Hero__title'>Trendo, the most powerful stock screener</h1>
            <div className='Hero__wrapper-subscribe'>
              <p className={`Hero__subtitle ${props.isLaunch ? '' : 'd-none d-md-block'}`}>
                Filter thousands of stocks at once to find the best investments. It’s easier than searching for a flight <strong>✈️</strong>
              </p>
              {props.isLaunch ? (
                <div className='d-md-none'>
                  <Download/>
                </div>
              ) : (
                <>
                  <p className='Hero__subtitle'> Join our waitlist and get priority access.</p>
                  <Subscribe/>
                </>
              )}
            </div>
            {/* Images */}
            <div className='Hero__stocks-absolute-img'></div>
            <div className='Hero__stocks-relative-img'></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

/**
 * Expose component
 */
export default Hero;
